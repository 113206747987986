import { cn } from '@/lib/utils';
import Marquee from '@/components/ui/marquee';
import { useTranslations } from 'next-intl';

const ReviewCard = ({ img, name, username, body }: { img: string; name: string; username: string; body: string }) => {
    return (
        <figure
            className={cn(
                'relative w-64 cursor-pointer overflow-hidden rounded-xl bg-[#6392f921] p-4'
                // light styles
                // dark styles
            )}
        >
            <div className='flex flex-row items-center gap-2'>
                <img className='rounded-full' width='32' height='32' alt={name} src={img} />
                <div className='flex flex-col'>
                    <figcaption className='text-sm font-medium dark:text-white'>{name}</figcaption>
                    <p className='text-xs font-medium dark:text-white/40'>{username}</p>
                </div>
            </div>
            <blockquote className='mt-2 text-sm'>{body}</blockquote>
        </figure>
    );
};

export function ReviewSlider() {
    const t = useTranslations('reviews');

    const reviews = [
        {
            name: t('review1.name'),
            username: t('review1.username'),
            body: t('review1.body'),
            img: 'https://avatar.vercel.sh/jack', // Логотип для Сбербанка
        },
        {
            name: t('review2.name'),
            username: t('review2.username'),
            body: t('review2.body'),
            img: 'https://avatar.vercel.sh/jill', // Логотип для Газпрома
        },
        {
            name: t('review3.name'),
            username: t('review3.username'),
            body: t('review3.body'),
            img: 'https://avatar.vercel.sh/john', // Логотип для РЖД
        },
        {
            name: t('review4.name'),
            username: t('review4.username'),
            body: t('review4.body'),
            img: 'https://avatar.vercel.sh/jane', // Логотип для Почты России
        },
        {
            name: t('review5.name'),
            username: t('review5.username'),
            body: t('review5.body'),
            img: 'https://avatar.vercel.sh/jenny', // Логотип для СДЭК
        },
        {
            name: t('review6.name'),
            username: t('review6.username'),
            body: t('review6.body'),
            img: 'https://avatar.vercel.sh/james', // Логотип для Альфа Банка
        },
    ];

    const firstRow = reviews.slice(0, reviews.length / 2);
    const secondRow = reviews.slice(reviews.length / 2);

    return (
        <div
            className='relative flex !bg-transparent w-full flex-col items-center justify-center overflow-hidden !rounded-none !border-l-0 !border-r-0  card_effect__3CjsY  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V md:shadow-xl'
            style={{ '--start-angle': '225deg' } as React.CSSProperties}
        >
            <Marquee pauseOnHover className='[--duration:20s]'>
                {firstRow.map((review, index) => (
                    <ReviewCard key={index} {...review} />
                ))}
            </Marquee>
            <Marquee reverse pauseOnHover className='[--duration:20s]'>
                {secondRow.map((review, index) => (
                    <ReviewCard key={index} {...review} />
                ))}
            </Marquee>
            <div className='pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-[#05060f] dark:from-background'></div>
            <div className='pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-[#05060f] dark:from-background'></div>
        </div>
    );
}
