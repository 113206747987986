'use client';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import ShimmerButton from '@/components/ui/shimmer-button';
import './slider.css'; // ваш файл с дополнительными стилями для карточек
import { EffectCoverflow, Pagination } from 'swiper/modules'; // <-- правильный импорт
import { motion } from 'framer-motion'; // импорт framer-motion
import { useProgressBar } from '@/components/main/ProgressBar'; // Импортируем хук для прогресс-бара
import { useSound } from '@/app/context/soundContext';
import { useTranslations } from 'next-intl';

const variants = {
    hidden: { opacity: 0, y: 50 }, // начальное состояние (скрыт и смещен)
    visible: { opacity: 1, y: 0 }, // конечное состояние (полностью видимый)
};

interface MenuComponentProps {
    toggleMenu: () => void;
    openModal: (menuItem: string) => void;
    isOpen: boolean;
    isModalOpen: boolean;
}

const CarouselComponent = ({ openModal, isOpen, isModalOpen }: MenuComponentProps) => {
    const t = useTranslations('Modules');
    const progress = useProgressBar(); // Используем прогресс-бар
    const { playHoverSound, playClickSound } = useSound();

    const handleClick = (menuItem: string) => {
        // Начинаем прогресс при клике
        progress.start();

        openModal(menuItem); // Открываем модальное окно
        // Завершаем прогресс после перехода
        setTimeout(() => {
            progress.done();
        }, 100); // Пример задержки для завершения прогресса
    };

    return (
        <>
            <motion.section
                className='hero__cards'
                initial='hidden' // начальное состояние
                animate='visible' // конечное состояние
                variants={variants} // параметры анимации
                transition={{ duration: 1 }} // длительность анимации
            >
                <div className='flex flex-col'>
                    <p className='hero__cards pb-4 '>{t('incudes')}</p>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        scrollbar={{ draggable: true }}
                        initialSlide={3}
                        // slidesPerView={3}
                        coverflowEffect={{
                            rotate: 100,
                            stretch: 0,
                            depth: 150,
                            modifier: 5,
                            slideShadows: false,
                        }}
                        pagination={true}
                        loop={true}
                        modules={[EffectCoverflow, Pagination]}
                        className='mySwiper'
                    >
                        <SwiperSlide>
                            <div className=' card_card-hero__pjFbO !h-[400px] card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m5')}</h3>
                                    <p className='py-2  !text-base !px-2'> {t('m5d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${6}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='  card_card-hero__pjFbO  !h-[400px] card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m6')}</h3>
                                    <p className='py-2 !text-base !px-2'> {t('m6d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${7}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='!h-[400px]  card_card-hero__pjFbO  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m7')}</h3>
                                    <p className='py-2  !text-base !px-2'>{t('m7d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${8}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='!h-[400px]  card_card-hero__pjFbO  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m1')}</h3>
                                    <p className='py-2 !text-base !px-2'>{t('m1d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${2}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='!h-[400px]  card_card-hero__pjFbO  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m2')}</h3>
                                    <p className='py-2  !text-base !px-2'>{t('m2d')} </p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${3}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='!h-[400px]  card_card-hero__pjFbO  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m3')}</h3>
                                    <p className='py-2  !text-base !px-2'>{t('m3d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${4}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='!h-[400px]  card_card-hero__pjFbO  card_card__VgpBR card_card-dark__ZxbMX card_card-size-large___Y9MM card_card-animated__5dDBc card_card-animated-loop__6iL1V'>
                                {/* <img src='Image1.jpg' alt='' /> */}
                                <div className='text-content pb-[25px]'>
                                    <h3 className='p-2'>{t('m5')}</h3>
                                    <p className='py-2  !text-base !px-2'>{t('m5d')}</p>
                                    <ShimmerButton
                                        className='shadow-2xl'
                                        // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                                        onClick={(e) => {
                                            handleClick(`menu${5}`);
                                            //@ts-ignore
                                            playClickSound(e);
                                        }} // Открываем модальное окно и запускаем прогресс
                                    >
                                        <span className='whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg'>
                                            {t('readmore')}
                                        </span>
                                    </ShimmerButton>{' '}
                                </div>
                            </div>{' '}
                        </SwiperSlide>
                    </Swiper>{' '}
                </div>
            </motion.section>
        </>
    );
};

export { CarouselComponent };
