import React, { useState } from 'react';
import Image from 'next/image';
import { AnimatedBeamDemo } from '../ui/Beam';
import { ReviewSlider } from '../slider/reviews';
import { useSound } from '@/app/context/soundContext';
import ContactModal from '../main/contactModal';
import dynamic from 'next/dynamic';
import { useTranslations } from 'next-intl';

const LoadingPlaceholder = () => <div className='spinner'></div>;
const DDRW = dynamic(() => import('@/components/main/ddrw').then((mod) => mod.DDRW), {
    loading: () => <LoadingPlaceholder />, // Загрузка
});

export default function AboutPage() {
    const [isModalOpen, setModalOpen] = useState(false);
    const { playHoverSound, playClickSound } = useSound();
    const t = useTranslations('about'); // Инициализация перевода

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <div className='w-full h-screen flex items-center justify-center'>
                <div className='h-screen overflow-y-scroll p-0 w-full'>
                    <div className='px-0 pt-4 max-w-screen-sm m-auto'>
                        <h1 className='text-xl font-bold mb-4 px-4'>
                            <Image
                                alt='logo'
                                src='/logo-ddrw.svg'
                                sizes='(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 100px'
                                width={120}
                                height={120}
                                priority={true}
                                className='pb-8 pt-4'
                            />
                            {t('title')}
                        </h1>
                        <p className='mb-2 px-4'>{t('description')}</p>
                    </div>
                    <div className='pt-4'>
                        <DDRW />
                    </div>
                    <br />
                    <div className='px-0 pt-4'>
                        <p className='mb-2 max-w-screen-sm m-auto px-4'>{t('experience')}</p>
                        <div className='px-4'>
                            <AnimatedBeamDemo />
                        </div>
                        <br />
                        <p className='mb-2 max-w-screen-sm m-auto px-4'>{t('pride')}</p>
                        <br />

                        <p className='mb-2 max-w-screen-sm m-auto px-4 pt-4'>{t('partnership')}</p>
                        <div className='w-full my-8 mb-2 md:mb-8 max-w-screen-sm m-auto px-4'>
                            <ContactModal />
                            <br />
                        </div>
                        <ReviewSlider />
                    </div>
                </div>
            </div>
        </>
    );
}
