import React, { useState } from 'react';
import Image from 'next/image';
import ContactModal from '../../main/contactModal';
import { ReviewSlider } from '../../slider/reviews';
import { useTranslations } from 'next-intl';

export default function Blockchain() {
    const t = useTranslations('videoConference'); // Подключаем переводы

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <>
            <div className='w-full h-screen flex items-center justify-center '>
                <div className='h-screen overflow-y-scroll p-0 w-full'>
                    <div className='px-0 pt-4 max-w-screen-sm m-auto'>
                        <h1 className='text-3xl font-bold mb-4 px-4 text-left'>
                            <Image
                                alt='logo'
                                src='/logo-ddrw.svg'
                                sizes='(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 100px'
                                width={120}
                                height={120}
                                priority={true}
                                className='pb-8 pt-4'
                            />
                            {t('title')}
                        </h1>

                        <p className='text-lg mb-4 px-4 text-left'>{t('description')}</p>

                        <div className=' rounded-lg px-4 py-6 mx-auto mb-8 max-w-screen-sm'>
                            <h2 className='text-2xl font-bold mb-4'>{t('benefitsTitle')}</h2>
                            <ul className='list-disc pl-6 text-lg '>
                                <li>{t('benefits.easyIntegration')}</li>
                                <li>{t('benefits.security')}</li>
                                <li>{t('benefits.flexibility')}</li>
                                <li>{t('benefits.highQuality')}</li>
                            </ul>
                        </div>
                    </div>

                    <div className='px-0 pt-4'>
                        <div className='w-full my-8 mb-2 md:mb-8 max-w-screen-sm m-auto px-4'>
                            <ContactModal />
                            <br />
                        </div>
                        <ReviewSlider />
                    </div>
                </div>
            </div>
        </>
    );
}
